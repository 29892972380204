<template>
  <el-dialog
    id="EditRestrictSKUGroupDialog"
    :title="title"
    :visible.sync="dialogShow"
    center
    modal
    top="5vh"
    :close-on-click-modal="false"
    width="496px"
    close-on-press-escape
    class="my-dialog abow_dialog"
    @opened="onShow"
    @keydown.enter.native="onCommit"
  >
    <el-form ref="EditRestrictSKUGroupForm" :model="model" :rules="rules" @submit.native.prevent="onSubmit" v-loading="loading">
      <el-row v-if="loading" justify="center" align="middle" type="flex" style="height:270px">
        <el-empty :image-size="100" :image="require('../../../assets/icons/no_data.svg')"></el-empty>
      </el-row>
      <el-row v-else>
        <el-form-item prop="group" :label="$t('Додати до групи')" v-if="operation == 'create' || operation == 'updateSKU'">
          <el-select style="width:100%" v-model="model.group">
            <el-option v-for="item in selectOptionsComp" :key="item.value" :label="item.label" :value="item.value" :placeholder="$t('Не обрано')"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="name" :label="$t('Назва')" v-if="model.group == '-1' || operation == 'update'">
          <el-input ref="nameInput" v-model="model.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-row justify="space-between" type="flex">
          <el-col :span="11">
            <el-form-item prop="faceCount" :label="$t('Кількість фейсів')">
              <el-input v-model="model.faceCount" ref="faceCountInput" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item prop="photoCount" :label="$t('Кількість фото')">
              <el-input v-model="model.photoCount" ref="photoCountInput" autocomplete="off"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item prop="attachFile" :label="$t('Прикріплений файл')">
          <el-tooltip placement="right" effect="light">
            <div slot="content">
              <div v-for="tt in attachFileComputedTooltip" :key="tt">{{ tt }}<br /></div>
            </div>
            <el-input
              v-model="attachFileComputed"
              autocomplete="off"
              suffix-icon="el-icon-more"
              @mousedown.native="attach($event)"
              readonly
              :placeholder="$t('Не обрано')"
            ></el-input>
          </el-tooltip>
        </el-form-item>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button ref="submitButton" native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <AttachTemplateSKUFile @attach-commit="onAttachCommit" bus-event="forms.attach.sku.group.file" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";

export default {
  name: "EditRestrictSKUGroup",
  components: {
    AttachTemplateSKUFile: () => import("@/components/forms/AttachTemplateSKUFile"),
  },
  data() {
    var that = this;
    var defaultValidateRule = { required: true, message: "Це поле є необхідним" };
    var digitalValueValidateRule = {
      validator: (rule, value, callback) => {
        if (value == undefined || value == "") {
          callback();
        } else if (!that.isNumeric(value) || value < 0) {
          callback(new Error("Поле має бути невід'ємним цілим числом"));
        } else {
          callback();
        }
      },
    };
    return {
      loading: false,
      dialogShow: false,
      title: $tt("Редагування групи товарів"),
      operation: null,
      rules: {
        name: this.nameRulesComp,
        faceCount: [digitalValueValidateRule],
        photoCount: [digitalValueValidateRule],
      },
      model: {},
      initModel: {},
    };
  },

  methods: {
    onCommit() {
      this.$refs.EditRestrictSKUGroupForm.validate((valid) => {
        if (valid) {
          this.$emit("commit", { operation: this.operation, model: this.prepareForCommit(this.model) });
        } else {
          this.$message.error($tt("Перевірте введені дані!"));
        }
        return false;
      });
    },
    onShow() {
      this.$refs.EditRestrictSKUGroupForm.resetFields();
      this.model = _.extend({}, this.initModel);
      this.$nextTick(() => {
        this.loading = false;
        this.$nextTick(() => {
          this.$refs.faceCountInput.focus();
        });
      });
    },
    prepareForCommit(data) {
      return data;
    },
    attach(event) {
      bus.$emit("forms.attach.sku.group.file.show", {
        title: $tt("Прикріплений файл"),
        initModel: {
          attach: this.model.attachFile,
          contractId: this.$store.getters["contracts/contract"]()._id,
          templateId: this.model.templateId,
          drugsId: this.model.forSKU,
        },
      });
    },
    onAttachCommit(data) {
      this.$set(this.model, "attachFile", data && data.id ? data : null);
    },
  },
  created() {
    bus.$on("forms.templates.groups.edit.show", (data) => {
      this.loading = true;
      this.title = (data && data.title) || this.title;
      this.operation = (data && data.operation) || "create";
      this.initModel = _.extend({ group: "0", faceCount: 1, photoCount: 1 }, data ? data.initModel : {});
      this.dialogShow = true;
    });
    bus.$on("forms.templates.groups.edit.hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {
    attachFileComputed: {
      get() {
        if (!this.model.attachFile) return null;
        else {
          return this.model.attachFile.srcName;
        }
      },
      set(value) {
        this.model.attachFile = value;
      },
    },
    attachFileComputedTooltip() {
      if (!this.model.attachFile) return [$tt("Не обрано")];
      else {
        return [
          `${$tt("Назва")}: ${this.model.attachFile.srcName}`,
          `${$tt("Розмір")}: ${this.bytesToSize(this.model.attachFile.size)}`,
          `${$tt("Завантажено")}: ${this.timestampToLocaleString(this.model.attachFile.uploaded)}`,
        ];
      }
    },
    selectOptionsComp() {
      return [
        { label: $tt("Без групи"), value: "0" },
        { label: $tt("Нова група"), value: "-1" },
      ].concat(this.$store.getters["templates/templateGroups"]());
    },
    nameRulesComp() {
      if (this.group == "-1") return [defaultValidateRule];
      else return [];
    },
  },
};
</script>
<style lang="scss">
#EditRestrictSKUGroupDialog {
  .el-dialog {
    max-height: 360px !important;
  }
}
</style>
